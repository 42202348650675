function GetBaseUrl() {
    if (window.location.href.indexOf("localhost") > -1) {
        return "http://localhost"
    } else {
        return "https://api.sensortest.imok.fr"
    }
}

export const sensorCounter = () => {
    return fetch(GetBaseUrl() + "/sensorcounter", {
        method: "GET",
    })
        .then(res => {
            if (res.status === 200) return res.json()
            console.error('sensorCounter returned an error:', res)
            return Promise.reject(new Error('sensorCounter returned an error'))
        })
}


export const fluidity = () => {
    return fetch(GetBaseUrl() + "/fluidity", {
        method: "GET",
    })
        .then(res => {
            if (res.status === 200) return res.json()
            console.error('sensorCounter returned an error:', res)
            return Promise.reject(new Error('sensorCounter returned an error'))
        })
}