import logo from './logo.svg';
import './App.css';
import Table from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

import { fluidity, sensorCounter } from './Api';

function App() {
  const [dataSensor, setDataSensor] = useState([]);
  const [dataChart, setDataChart] = useState([]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    updateDataSensor();
    setInterval(() => updateDataSensor(), 10000);
  }, []);

  const updateDataSensor = () => {
    sensorCounter().then(res => {
      setDataSensor(res)
    }).catch(err => { console.error(err.Error) })

    fluidity().then(res => {
      let fluidity = []

      res.sensors.map(itmSensor => {
        let fluiditySensor = []
        fluiditySensor.push(["x", itmSensor.model + " - " + itmSensor.name])

        if (itmSensor.fluidity != null)
          itmSensor.fluidity.map(fl => {
            let insertFluidity = [new Date(fl.date), fl.count]
            fluiditySensor.push(insertFluidity)
          });


        fluidity.push(fluiditySensor)
      });

      //console.log(fluidity)
      setDataChart(fluidity)

    }).catch(err => { console.error(err.Error) })
  };

  return (
    <div style={{ margin: 20 }}>
      <h2>Sensor Viewer</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SensorID</th>
            <th>Model</th>
            <th>Name</th>
            <th>Kind</th>
            <th>IN</th>
            <th>Out</th>
            <th>Count</th>
            <th>Last update</th>
          </tr>
        </thead>
        <tbody>
          {dataSensor.map((ds) => (
            <tr>
              <td>{ds.sensor_id}</td>
              <td>{ds.model}</td>
              <td>{ds.name}</td>
              <td>{ds.kind}</td>
              <td>{ds.in}</td>
              <td>{ds.out}</td>
              <td>{ds.count}</td>
              <td>{ds.last_update}</td>
            </tr>
          ))}

        </tbody>
      </Table>

      <h2>Charts</h2>
      {dataChart.map((data) => (
        <Chart
          width={'auto'}
          height={'auto'}
          chartType="SteppedAreaChart"
          loader={<div>Loading Chart</div>}
          data={data}
          options={{
            legend: { position: 'top' },
            hAxis: {
              title: 'Time'
            },
            vAxis: {
              title: 'People Counter'
            },
            series: {
              1: { curveType: 'function' },
            },
          }}
          rootProps={{ 'data-testid': '2' }}
        />
      ))}

    </div>
  );
}

export default App;
